import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  root: {
    flexGrow: 1
  },
  pcrHeader: {
    gridArea: "header",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    padding: "5px 20px",
    lineHeight: "1.1em",
    backgroundColor: "white",
    position: "fixed",
    left: "0",
    right: "0",
    top: "0",
    zIndex: "999",
    boxShadow: "0 1px 3px steelblue"
  },
  pcrHeaderUser: {
    textDecoration: "none",
    fontSize: "16px",
    marginTop: "29px",
    color: "#5a5a5a !important",
    zIndex: "0",
    display: "flex",
    position: "absolute",
    right: "15px",
    top: "-15px"
  },
  papr: {
    width: 250,
    height: 36,
    marginTop: "3px",
    marginLeft: "215px",
    borderRadius: "1px",
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)"
  },

  inputBase: {
    width: "80%",
    fontSize: "16px",
    bottom: "5px"
  },

  inputRoot: {
    color: "inherit"
  },

  treeAdmin: {
    marginLeft: "6px",
    fontSize: "13px",
    fontWeight: "100 !important"
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch"
      }
    }
  },

  gridRight: {
    marginLeft: "15px",
    marginRight: "15px"
  },

  expandIcon: {
    marginTop: "-2px"
  }
}));

const Header = props => {
  const { classes } = useStyles();
  const userInfo = useSelector(state => state.user.userInfo);
  const { onExit } = props;

  let logo = (
    <img
      src={process.env.PUBLIC_URL + "/oneConfigLogoSecondary.svg"}
      style={{
        height: "48px"
      }}
      alt="pcr-header-logo-img"
    />
  );
  let title = "One Config IQ";
  const notPartner = userInfo?.userType?.toLowerCase() !== "partner";
  if (notPartner) title = "One Config IQ Simulation";

  return (
    <Grid
      className={classes.pcrHeader}
      style={{
        height: "64px",
        background: "white"
      }}
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Grid container direction="row" alignItems="center">
        <Grid>{logo}</Grid>
        <Grid style={{ paddingLeft: "24px" }}>
          <Typography
            variant="h5"
            style={{
              color: "black",
              fontSize: "28px"
            }}
          >
            {title}
          </Typography>
        </Grid>

        {userInfo && (
          <div>
            <Grid className={classes.pcrHeaderUser}>
              <Grid
                className="pcr-header-profile-info"
                style={{
                  color: "inherit"
                }}
              >
                <div className="pcr-header-username">
                  {userInfo?.userEmail || "Admin"}
                </div>
                <Grid container direction="row" justifyContent="flex-end">
                  <Grid>
                    <Typography className={classes.treeAdmin}>
                      Admin User
                    </Typography>
                  </Grid>
                  <Grid>
                    <ExpandMoreIcon className={classes.expandIcon} />
                  </Grid>
                </Grid>
              </Grid>

              <span
                className="pcr-header-logout"
                onClick={() => {
                  onExit();
                }}
              >
                <Grid container style={{ flexWrap: "unset" }}>
                  {notPartner && (
                    <Grid item xs={12} style={{ margin: "0px 6px" }}>
                      <img
                        src={`${process.env.REACT_APP_SVG_BASE_URL}/Header/user_profile2.svg`}
                        alt="user-profile"
                      />
                    </Grid>
                  )}
                </Grid>
              </span>
            </Grid>
          </div>
        )}
      </Grid>
      {props.children}
    </Grid>
  );
};

export default Header;
