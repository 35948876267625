import Cookie from "js-cookie";
import jwt_decode from "jwt-decode";
import {
  clearUserInfo,
  tokenRenewFail,
  tokenRenewRequest,
  tokenRenewSuccess,
  userAccessToken,
  userLoginFail
} from "../reducers/userReducer";

/**
 * TODO: Fake, not used to login via direct API call
 * @param {string} email
 * @param {string} password
 * @returns
 */
export const getClientToken = async acces_token => {
  let header = {
    Authorization: `Bearer ${acces_token}`
  };

  let response = await fetch(
    `${process.env.REACT_APP_GRAPHQL_BASE_URL}/auth/getClientToken`,
    {
      method: "GET",
      headers: header
    }
  );
  let clientToken = await response.text();
  Cookie.set("client_token", clientToken);
  return clientToken;
};
/**
 * Store access_token and expires in Cookies
 * @param {string} access_token
 * @param {number} expires
 * @returns
 */
export const receiveAccessToken = (access_token, expires_at) => dispatch => {
  try {
    const user = jwt_decode(access_token);
    if (expires_at > new Date().getTime() / 1000) {
      Cookie.set("userInfo", user);
      Cookie.set("access_token", access_token);
      Cookie.set("expiresAt", expires_at);
      dispatch(userAccessToken({ user, token: access_token, expires_at }));
      getClientToken(access_token);
      return true;
    } else {
      throw new Error({ message: "Token expired!" });
    }
  } catch (err) {
    dispatch(userLoginFail(err?.message));
    return false;
  }
};

/**
 * Renew token
 * @returns New token
 */
export const renewToken = () => async dispatch => {
  dispatch(tokenRenewRequest());
  const jwt_token = Cookie.get("access_token");
  const refreshAPI = `${process.env.REACT_APP_GRAPHQL_BASE_URL}/model`;
  try {
    const modelQuery = `query{renewToken}`;
    const refreshToken = await fetch(refreshAPI, {
      method: "POST",
      headers: {
        authorization: `Bearer ${jwt_token}`
      },
      body: JSON.stringify({
        query: modelQuery
      })
    });
    const refreshAuthToken = refreshToken.json();
    if (refreshAuthToken && refreshAuthToken.data) {
      const { data } = refreshAuthToken;
      if (!data?.errors) {
        const tokenDetails = data?.data?.renewToken;
        clearCookies();
        Cookie.set("access_token", tokenDetails.token, {
          path: "/",
          domain: window.location.hostname
        });
        Cookie.set("expires", tokenDetails.expires_at, {
          path: "/",
          domain: window.location.hostname
        });
        window.authToken = tokenDetails.token;
        dispatch(tokenRenewSuccess(tokenDetails.token));
        getClientToken(tokenDetails.token);
      } else {
        const errors = data?.errors?.map(error => {
          const fullMessage = JSON.parse(error.message);
          return `${fullMessage.code}: ${fullMessage.message}`;
        });
        console.log(errors);
        dispatch(tokenRenewFail(errors));
        logout();
      }
    }
  } catch (err) {
    console.log(err);
    dispatch(tokenRenewFail(err.message));
    logout();
  }
};

const clearCookies = () => {
  Cookie.remove("access_token");
  Cookie.remove("expiresAt");
  Cookie.remove("access_token", { path: "/", domain: ".hpicloud.net" });
  Cookie.remove("expires", { path: "/", domain: ".hpicloud.net" });
  Cookie.remove("access_token", { path: "/", domain: ".hp.com" });
  Cookie.remove("expires", { path: "/", domain: ".hp.com" });
};

/**
 * Clear token information on logout
 * @returns null
 */
export const logout = () => async dispatch => {
  dispatch(clearUserInfo());
  Cookie.remove("userInfo");
  Cookie.remove("access_token");
  Cookie.remove("expiresAt");
  Cookie.remove("access_token", { path: "/", domain: ".hpicloud.net" });
  Cookie.remove("client_token", { path: "/", domain: ".hpicloud.net" });
  Cookie.remove("expires", { path: "/", domain: ".hpicloud.net" });
  Cookie.remove("access_token", { path: "/", domain: ".hp.com" });
  Cookie.remove("client_token", { path: "/", domain: ".hp.com" });
  Cookie.remove("expires", { path: "/", domain: ".hp.com" });
  sessionStorage.clear();
  console.log("INFO cleared");
  delete window.authToken;
  delete window.uiSpecData;
  delete window.uiSpecFileName;
  delete window._modelJson;
  localStorage.getItem("isDebug") === "true" &&
    console.log("window attributes cleared");
};
