import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { withRouter } from "react-router-dom";

const LandingPage = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="80vh"
    >
      <Typography variant="subtitle1" sx={{ color: "red" }}>
        This tool has been retired. Please use OCI for internal view and dummy
        partner access within IQ for partner view.
      </Typography>
    </Box>
  );
};

export default withRouter(LandingPage);
